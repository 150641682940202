import PropTypes from "prop-types";
import React from "react";

import addI18nContextConsumer from "../i18n/addI18nContextConsumer";
import i18n from "./Legals.i18n.json";
import styles from "./Legals.module.scss";

const Legals = ({ strings }) => (
  <div className={styles["container"]}>
    <div className={styles["body"]}>
      <h1>{strings["title"]}</h1>
      <p>
        {strings["owner"]}
        <strong>831 451 018.</strong>
      </p>
      <p>
        {strings["capital"]}
        <b>23 990€</b>
      </p>
      <p>
        {strings["location"]}
        <b>40 Avenue Aristide Briand 92220 Bagneux</b>
      </p>
      <p>
        {strings["phone"]}
        <b>+33 1 41 24 43 23</b>
      </p>
      <p>
        {strings["email"]}
        <b>contact@jawg.io</b>
      </p>
      <p>
        {strings["dpo"]}
        <b>Olivier Duvoid</b>
      </p>
      {strings["claims"]}
      <b>Olivier Duvoid</b>.
    </div>
  </div>
);

Legals.propTypes = {
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Legals);
