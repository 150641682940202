import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Legals from "../components/legals/Legals";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function LegalsPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "Légales"]}
          location={location}
          metaDescription="Sur cette page, vous trouverez les mentions légales de Jawg."
          title="Mentions légales"
        />
        <Page>
          <Legals />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

LegalsPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default LegalsPage;
